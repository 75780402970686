@import '../../theme/theme.less';

.error-boundary-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: @lightThemeBackground;
  flex-direction: column;
}

.error-boundary-title {
  font-size: 1.5rem;
  padding: 2rem;
}

.error-boundary-image {
  height: 20vh;
}

.error-boundary-button {
  &-reload
  {
    color: @white !important;
    border-color: @cboPrimaryColor;
    background: @cboPrimaryColor;
    &:focus, &:hover{
      background: #16868c;
    }
  }

  border-radius: .25rem !important;  
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
