@import '../../theme/theme.less';
@import '../../App.mixins.less';

.cbo-header-container {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 4.8rem;
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  box-shadow: 3px 0px 6px @shadow1;
  background-color: @white;

  .cbo-header {
    .flex-row-space-between;
    align-items: center;
    width: 100%;
    &-title {
      .headerTitle;
      padding-left: 2rem;
      font-size: 1.1rem;
    }

    .cbo-summary-title {
      line-height: normal;
      padding: 0 1rem;

      .cbo-header-back-btn {
        font-weight: bold;
        font-size: 0.7rem;
        color: @lightThemeGreenButton;
        display: inline-flex;
        padding: 0;
        height: auto;
      }

      &-bread {
        padding: 0;
        line-height: 1rem;
        font-size: 0.8rem;
        margin: 0;
        .client-logo-container {
          &:has(.client-name-initial) {
            margin-top: 0.5rem;
          }
          .client-name-initial {
            height: 2rem;
            font-size: 1rem;
            padding: 0.25rem 0.5rem;
            margin-right: 0.5rem;
            background: @white4;
            border-radius: 8px;
            color: @lightThemeCBOGreen;
          }
        }
        .client-logo {
          height: 2.5rem;
          width: 2.55rem;
          object-fit: contain;
        }
        &.cbo-marketplace {
          font-size: 1rem;
        }

        .cbo-header-engagement-name {
          color: @black;
        }
      }
    }

    &-right-side {
      .flex-row;
      align-items: center;
      .zoom-changer {
        padding: 0 0.5rem;
        .ant-radio-button-wrapper {
          border: 1px solid @lightThemeCBOGreen;
          height: 1.5rem;
          line-height: 1.5rem;
          background-color: @white5;
          &-checked {
            background-color: @lightThemeCBOGreen;
          }
        }
        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
          background-color: @lightThemeCBOGreen;
        }
        .ant-radio-button-wrapper:not(:first-child)::before {
          background-color: @lightThemeCBOGreen;
        }
        &-default {
          font-size: 14px;
        }
        &-minus {
          font-size: 12px;
        }
        &-plus {
          font-size: 15px;
        }
        &-container {
          padding: 0 1rem;
        }
      }
    }

    &-notification {
      height: 1.5rem;
    }

    &-profile {
      .flex-row;
      align-items: center;
      padding: 0.5rem;
      background-color: @lightThemeBackgroundGray;
      margin: 0.5rem 1rem;
      border-radius: 5rem;

      &-pic {
        height: 3rem;
        border-radius: 50%;
        overflow: hidden;

        img {
          vertical-align: baseline;
        }
      }
      &-details {
        .flex-column;
        justify-content: center;
        line-height: 20px;
        padding: 0 1rem;

        .cbo-profile-name {
          font-size: 0.8rem;
        }
        .cbo-profile-title {
          font-size: 0.5rem;
          color: @lightThemeCBOGreen;
        }
      }
      .cbo-header-profile-popover {
        width: 10rem;
        height: 3rem;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      &-arrow-down {
        padding: 0 1rem;
      }
    }
  }
}
.ant-popover.cbo-logout {
  .ant-popover-content {
    width: 15rem;
    .ant-popover-inner {
      display: flex;
      justify-content: center;
      .ant-popover-inner-content {
        padding: 0;
      }
    }
  }
}
