@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.zoom-default {
  zoom: .80;
}

.zoom-minus {
  zoom: .67;
}

.zoom-plus {
  zoom: 1;
}
.vh-zoom-default{
  height: 125vh !important;
}
.vh-zoom-minus{
  height: 150vh !important;
}
.vh-zoom-plus{
  height: 100vh !important;
}

.zoom-moz {
  -moz-transform: scale(.67);
  -moz-transform-origin: 0 0;
}
