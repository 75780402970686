@import '../../theme/theme.less';
@import '../../App.mixins.less';

.cbo-sidebar-container {
  background-color: @white;
  overflow: auto;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;

  .cbo-logo {
    width: 100%;
    padding-left: 0.25rem;
    cursor: pointer;

    img {
      height: 2rem;
      display: block;
      margin: 1.4rem auto;
    }
  }

  .ant-layout-sider-children {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .cbo-sidebar-menu-container {
      height: 100%;
      display: flex;
      overflow-x: hidden;
      justify-content: space-between;
      flex-direction: column;
    }

    .cbo-sidebar-menu {
      .cbo-sidebar-top-menu {
        .ant-menu-vertical {
          border-right: none;
        }
      }
    }

    .cbo-sidebar-bottom-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 2;
      background-color: @cboSidebar;
      width: 100%;

      .divider-line {
        background-color: @cboSidebar;
      }
      .ant-menu-root .ant-menu-item {
        min-height: 3.75rem;
      }
    }

    .ant-menu-root {
      margin-right: 0;

      .ant-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 4.25rem;
        margin: 0;
        background-color: @cboSidebar;
        color: @white;
        padding: 0.5rem 0.5rem !important;
        font-size: 10px;
        width: 8rem;

        .cbo-menu-icon {
          height: 1.4rem;
          filter: brightness(0) invert(1);
        }

        span {
          margin-left: 0;
          white-space: normal;
          overflow: visible;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03rem;
          font-size: 0.8rem;
          margin-top: 0.25rem;
        }
      }

      .ant-menu-item-selected {
        background-color: @cboSidebarMenu;
        color: @white;
      }

      .ant-menu-item-active {
        border-radius: 0px;

        &.tour-settings.ant-dropdown-open,
        &.tour-assesAssets.ant-dropdown-open,
        &.tour-testAssets.ant-dropdown-open,
        &.tour-modernizeAssets.ant-dropdown-open,
        &.tour-operateAssets.ant-dropdown-open {
          border-radius: 2px;
          background-color: @cboSidebarMenu !important; //added to ovverride the inline style
        }
      }

      .active-settings {
        background-color: @cboSidebarMenu;
      }

      .inactive-settings {
        background-color: @cboSidebar;
      }
    }
  }
}

.ant-dropdown.ant-dropdown-placement-topLeft.settings-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.qe-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.migration-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.cloud-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.operate-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.settings-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.qe-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.migration-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.cloud-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.operate-menu {
  min-width: 300px !important;
  left: 110px !important;

  ul {
    border-bottom: 8px solid @lightThemeCBOGreen;
  }

  .ant-dropdown-menu-item {
    border-bottom: 1px dashed grey;
    line-height: 30px;
    padding: 0.6rem 1rem 0.6rem 1.5rem;

    &-disabled {
      cursor: default;
      opacity: 0.5;
    }

    &:hover {
      background-color: @tableHeader;
    }
  }

  .ant-dropdown-menu-title-content {
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 21px;
    color: @black;
  }
}

.ant-dropdown.ant-dropdown-placement-topLeft.settings-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.qe-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.migration-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.cloud-assets-menu,
.ant-dropdown.ant-dropdown-placement-topLeft.operate-menu {
  ul {
    margin-bottom: -5rem !important;
  }
}

.ant-dropdown.ant-dropdown-placement-bottomLeft.settings-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.qe-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.migration-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.cloud-assets-menu,
.ant-dropdown.ant-dropdown-placement-bottomLeft.operate-menu {
  ul {
    margin-top: -5rem !important;
  }
}
