// Colors
@primaryColor: #1e1e2d;
@cboPrimaryColor: #0297a8;
@backgroundColor: #f6faf1;
@accentColor: #d9ef79;
@white: #ffffff;
@black: black;
@black2: #0000000b;
@black3: #252534;
@black4: #000000d9;
@headerText: #000000cc;
@textColor: fade(black, 85%);
@primaryButtonColor: #007680;
@shadow1: #00000036;
@shadow2: #15223214;
@shadow3: #00000029;
@dividerColor: #707070;
@dividerColor2: #d3d3d3;
@dividerColor3: #f2f2f2;
@dividerColor4: #363643;
@borderGrey: #d9d9d9;
@defaultButtonColor: #707070;
@configureModalBackground: #f6faf1;
@unfilledProgressBarColor: rgb(231, 240, 187);
@subtitleText: #707070;
@discoverAssess: #86bc26;
@buildDeploy: @cboPrimaryColor;
@migrateModernize: #1b9cc8;
@operate: #ff9d00;
@securityGovernance: #00a2df;
@successBg: #bee8f8;
@successText: #00a2df;
@failureBg: #f8d9d1;
@failureText: #e86f51;
@dashColor: #adadad;
@subtitleColor: #11193c;
@gradientColor1: #86bc26;
@gradientColor2: #1ebbca;
@iconcolorbackground: #eafdf9;
@iconcolor: #0fc39a;
@errorTableHeader: #e6efdc;
@errorTableBackground: #f9f9f9;
@errorTag: #c60e52;
@errorTagBackground: #c60e521a;
@borderColor: #e2e2e2;
@dotColor: #526888;
@summaryGreen: #bade7b;
@summarySubtitle: #606060;
@summaryPinkCard: #d03c33;
@summaryPinkText: #fff0ef;
@summaryGreenColor: #88930a;
@summaryGreenText: #f4f7d2;
@summaryBlueCard: #e8f8fd;
@summaryBlueText: #0ca0ce;
@summaryOrangeText: #cb7700;
@summaryOrangeCard: #fdf1e0;
@summaryBackground: #f0f8f8;
@progressColor1: #ffbd84;
@progressColor2: #8edbce;
@progressColor3: #7bd3f5;
@increaseGreen: #0fc39a;
@decreaseRed: #ff5059;
@activeColor: #c4d600;
@inactiveColor: #dedbdb;
@inprogessColor: #ffc700;
@progressColor: #ffe477;
@progressColorText: #814c02;
@darkGreen: #004139; // Global Styles
@darkGreen1: #006400; // Global Styles
@lightRed: #ce6985;
@viewAllDarkBlue: #0076a8;
@darkBlue: #232d42;
@darkRed: #bf6356;
@red: #ff4d4f;
@orange: #cc8119;
@lightOrange: #ffbc0d;
@green: #0f9881;
@whiteGreen1: #dae6cd;
@whiteGreen2: #edf4e8;
@keyContactsGreen: @cboPrimaryColor;
@passedGreen: #0fc39a;
@drawerBackgroundGeen: #f6fded;
@tableHeadGreen: #6e7164;
@lightGrey: #868a91;
@darkGrey: #6f7379;
@optionGrey: #dfead3;
@pipelineDividerGrey: #e4e6e7;
@textSkelitonGrey: #f5f5f5;
@imgSkelitonGrey: #b4b6b1;
@navSkelitonGrey: #d6d6d6;
@headerSkelitonGrey: @navSkelitonGrey;
@avatarSkelitonGrey: @navSkelitonGrey;
@borderSkelitonGrey: #c6c6c6;
@darkBorderGrey: #d3d2d3;
@blueGrey: #677075;
@grey1: #eeeeee;
@grey2: #faf2ed;
@architectureControls: #e6efdc;
@architectureControlsDisabled: #979797;
@cardGreyBgColor: #fafdfe;
@cardBlueBgColor: #f2f9fc;
@cardOrangeBgColor: #fff8e4;
@cardRedBgColor: #fcf6fa;
@customizebgColor: #e0eee8;
@customizebgColor2: #cddfeb;
@lightThemeBackground: #f6f6f6;
@lightThemeGreenButton: @cboPrimaryColor;
@lightThemeStatusInProgress: #f8ce46;
@lightThemeStatusCompleted: #a8d2cf;
@lightThemeStatusTodo: #eea851;
@lightThemeButtonBlue: #0076a8;
@lightThemeCBOGreen: #86bc26;
@lightThemeBackgroundGray: #fafafa;
@lightThemeBorderGray: #616161;
@cboConfigDefaultBackgroundGray: #727272;
@viewIcon: #6076ff;
@viewIcon2: #6076ff73;
@cboJourneyTitle: #292929;
@cboCollapseBorderBottom: #8c8c8c;
@colorTitle: #222838;
@colorSmallFont: #292d32;
@backgroundDetails: #f3f9fd;
@borderContent: #999999;
@borderControl: #6e7164;
@tagColor: #1672ec;
@iconBorderColor: #9fa9bd;
@selectedItemBackground: #f3f8ee;
@inputTextFieldBorderColor: #c3c3c3;
@cardButton: #333333;
@tableHeader: #c4dad0;
@tableDivider: #c4c4c4;
@tagInActive: #f0eeee;
@tagActive: #4eb9f53d;
@removeItem: #e06767;
@gray1: #666666;
@gray2: #666;
@tabDivider: #5d6c6e;
@cboSidebar: #4ba8aa;
@cboSidebarMenu: #007680;
@cboFloatingButton: #8fc72b;
@optionalColor: #7c4dff;
@tagYellowColor: #d4a302;
@ddTagColor: #92b944;
@ddColorCodeIncluded: #5b5b5b;
@journeyTagApproved: #ccdb8f;
@journeyTagRejected: #ee9f74;
@journeyHeader: #d7ede3;
@journeyDummyScreen: #f7f7f7;
@excludedDD: #0076a8;
@tagBGColor: #dbf0f2;
@tagTextColor: #2e2e30;
@consideredStatus: #74b540;
@readStatus: #6398de;
@cfsQoption1: #9e8f95;
@cfsQoption2: #d97559;
@cfsQoption3: #e8a56c;

@pursuitWon: #b2d965;
@pursuitLost: #f6c5c5;
@pursuitProposed: #ededed;
@pursuitAbandoned: #e8a56c;
@domain-bg-red: #fff3f3;
@domain-bg-yellow: #fff6da;
@domain-bg-blue: #f2f7ff;
@domain-bg-purple: F9F6FF;

@user-avatar-bg: #5b6057;
@cc-tags-bg: #f3f6ee;

@cwb-auth-color: #e60404f7;

@inactive-bg: #e4e9f0;
@inactive-color: #536789;

@jira-ticket-color: #5e6c84;
@jira-status-color: #263b4a;

@white2: #f7faf2;
@white3: #f2f4ee;
@white4: #f3f7ee;
@white5: #f4f7ef;

@legend-bubble1: #e19d3f;
@legend-bubble2: #ff725e;
@legend-bubble3: #4cacc4;
@legend-bubble4: #3c8ecf;
@legend-bubble5: #70ab46;
@legend-bubble6: #92e3a9;

@additional-card-border: #b4aeae;
@feature-name-bg: #f4f9fd;
.headerTitle {
  color: @headerText;
  font-weight: bold;
  font-size: 22px;
}

.boxShadow {
  box-shadow: 0px 1px 4px @shadow2;
}
.box-shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.box-shadow-button {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
}

/**
* antd global variables (refer here for list of all default variables here -
* https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
*/
@primary-color: @primaryColor; // primary color for all components
@layout-header-background: @backgroundColor;
@layout-header-height: 4.25rem;
@layout-header-padding: 0;
@border-radius-base: 6px;
@btn-primary-color: @white;
@btn-primary-bg: @primaryButtonColor;
@btn-height-base: 42px;
@input-height-base: 42px;
@btn-default-border: @defaultButtonColor;
@input-border-color: @defaultButtonColor;
@select-border-color: @defaultButtonColor;
@padding-sm: 7px;
@input-padding-vertical-base: 7px;
@select-selection-item-bg: #80808078;
@select-item-selected-color: white;
@font-size-base: 13px;

.ant-modal-header {
  padding: 16px 32px 8px 32px;
  color: rgba(0, 0, 0, 0.85);
  background: @white;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
}
.ant-modal-body {
  padding: 8px 50px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-title {
  padding: 8px 8px 16px 8px;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
  border-bottom: 1px dashed @dividerColor2;
}

.ant-modal-wrap:has(.add-edit-feature) {
  z-index: 99999;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  position: absolute;
  right: 7px;
}
.ant-popover {
  z-index: 99999;
}
.ant-modal-footer {
  padding: 22px 50px;
  text-align: right;
  background: transparent;
  border: 0;
  border-radius: 0 0 6px 6px;
}

.ant-select {
  font-size: 1rem;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: @lightThemeBorderGray;
  background-color: @backgroundDetails;
  border-left: 0.03125rem solid @cboPrimaryColor;
  border-right: 0.03125rem solid @cboPrimaryColor;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 2.3rem;
  padding: 0 11px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 2.3rem;
  transition: all 0.3s;
}

.ant-btn-primary {
  background-color: @cboPrimaryColor;
  border-radius: 0.25rem;
  border: none;
  height: 3rem;
  font-size: 15px;
  padding: 7.2px 15px;
  min-width: 7.5rem;
}

.ant-btn-default {
  border: 1px solid @cboPrimaryColor;
  color: @cboPrimaryColor;
  border-radius: 0.25rem;
  height: 3rem;
  min-width: 7.5rem;
  font-size: 15px;
  padding: 7.2px 15px;
}

//Overiding Scrollbar style
::-webkit-scrollbar {
  width: 6px; //Vertical ScrollBar Width
  height: 6px; //Horizontal ScrollBar Heigth
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: @select-selection-item-bg;
}

/* Changes cursor to pointer */
.cursor-pointer {
  cursor: pointer;
}

.color-white {
  color: @white;
}

.widget-list-styles {
  margin: 0;
  overflow: auto;
  height: 15rem;
}

.no-border {
  border: none;
}

.m-1 {
  margin: 1rem;
}

.action-icon-btn-style,
.action-icon-btn-style:hover {
  border: none;
  height: 2rem;
  width: 2rem;
  margin-bottom: 0.5rem;
  box-shadow: none;
  background-color: transparent;
  transition: none;
}
.create-pipeline-btn {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.create-pipeline-icon {
  margin-right: 0.5rem;
}

.mb-1-dot-5 {
  margin-bottom: 1.5rem;
}

.w-50 {
  width: 50%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.mr-1 {
  margin-right: 1rem;
}

.align-item-center {
  display: flex;
  align-items: center;
}

.table-style {
  margin: 0 1rem;
  .ant-table-thead .ant-table-cell {
    background: @tableHeadGreen;
    color: @white;
  }
  .ant-table-thead .ant-table-cell:not(.ant-table-header-search):not(.ant-table-action-header) {
    padding-top: 3.7rem;
  }
  .ant-table-row .ant-table-cell {
    height: 2rem;
    padding: 0.3rem 1rem;
  }
  .ant-table-row-level-0:nth-child(even) {
    background: fade(@accentColor, 10%);
  }
  .ant-input-affix-wrapper {
    height: 1.8rem;
    background: @dividerColor4;
    width: 14rem;
    margin-bottom: 0.5rem;
  }
  .ant-input:not(.edit-project-input) {
    background: @dividerColor4;
    color: @backgroundColor;
  }
}

.cbo-table {
  .ant-table-thead .ant-table-cell {
    background: @tableHeader;
    height: 4rem;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    height: 0%;
  }

  .ant-table-tbody > tr > td {
    font-size: 0.8rem;
    font-weight: 600;
    // border-bottom: 0.1rem dashed #C4C4C4;
    &.capabilities {
      font-weight: 400;
      font-size: 0.75rem;
      .cap-list-more {
        color: @lightThemeGreenButton;
      }
    }
  }
  .ant-table-pagination {
    display: flex;
    justify-content: center;
    padding: 0 0 2rem 0;
  }
}
.ant-pagination-total-text {
  color: @textColor;
  font-size: 1rem;
  margin-right: 1rem;
}

.landing-zone-table-container {
  height: 37.5rem;
  background-color: @white;
  border: 1px solid fade(@dividerColor, 50%);
  overflow: auto;
}

.ant-switch {
  background-color: @white;
  border: 1px solid @inputTextFieldBorderColor;
  height: 0.7rem;
}

.ant-switch-handle {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.ant-switch-handle::before {
  position: absolute;
  top: -7px;
  right: 1px;
  bottom: 5px;
  left: -2px;
  background-color: @inputTextFieldBorderColor;
  border-radius: 9px;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
  content: '';
}

.ant-switch:focus {
  box-shadow: none;
}
.ant-switch-checked:focus {
  box-shadow: none;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px);
}

.ant-switch-checked {
  border: 1px solid @primaryButtonColor;
  .ant-switch-handle::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 5px;
    left: 0;
    background-color: @primaryButtonColor;
    border-radius: 9px;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    content: '';
  }
}

.view-more-btn-container {
  display: flex;
  justify-content: center;
  transform: translateY(1rem);
}

.view-more-btn {
  top: 0.4rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  width: 5.5rem;
  justify-content: center;
  background: @white 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #1522322b;
  border: 0.5px solid #0000000b;
  border-radius: 6px;
}

.view-more-btn-text {
  font-size: 0.7rem;
}

.home-page-switch {
  margin-right: 0.8rem;
}

.switch-with-tick.ant-switch-checked {
  background-color: @primaryButtonColor;
  margin-right: 0.5rem;
  .ant-switch-handle::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -6px;
    right: 0;
    bottom: 5px;
    left: 0;
    background-color: @white;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    content: '\2713\0020';
    border: 1px solid @primaryButtonColor;
    border-radius: 1rem;
    color: @primaryButtonColor;
  }
}

.ml-0-dot-5 {
  margin-left: 0.5rem;
}

.arrow-icon {
  margin-left: 0.5rem;
  font-size: 0.7rem;
}
.anticon-ellipsis,
.anticon-more {
  svg {
    font-size: 1.25rem;
  }
}

.landing-zone-name {
  display: flex;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  margin: 0 0.5rem;
  font-size: 0.9rem;
}

.landing-zone-name-greyed {
  .landing-zone-name;
  margin-left: -0.1rem;
  opacity: 0.6;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.ant-input[disabled] {
  border-color: @borderGrey;
}

.ant-input-affix-wrapper {
  border: 1px;
  border-radius: 2px;
  border-color: @inputTextFieldBorderColor;
  box-shadow: 0 0 0 2px rgba(30, 30, 45, 0.2);
  height: 3rem;
  .ant-input-prefix {
    margin-right: 1rem;
    margin-left: 0.5rem;
  }
  .ant-input-prefix {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: @primaryButtonColor;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio:hover .ant-radio-inner {
  border-color: @primaryButtonColor;
}

.flex-col {
  display: flex;
  flex-direction: row;
}

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-space-between {
  display: flex;
  justify-content: space-between;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1000px) {
  article {
    font-size: 70%;
  }
  span {
    font-size: 90%;
  }
  .cloud-summary-card-icon {
    height: 3rem;
    width: 3rem;
  }
}

@media screen and (max-width: 800px) {
  article {
    font-size: 50%;
  }
  span {
    font-size: 70%;
  }
  .cloud-summary-card-icon {
    height: 3rem;
    width: 3rem;
  }
}

@media screen and (max-width: 400px) {
  article {
    font-size: 50%;
  }

  span {
    font-size: 80%;
  }
  .cloud-summary-card-icon {
    height: 3rem;
    width: 3rem;
  }
}

.badge-success {
  border-radius: 40px;
  background-color: @activeColor;
  color: @black;
  padding: 0.1rem 0rem;
  width: 6rem;
  text-align: center;
}

.badge-warning {
  border-radius: 40px;
  background-color: @failureBg;
  color: @black;
  padding: 0.1rem 0rem;
  width: 6rem;
  text-align: center;
}

.logout-modal {
  .ant-modal-content .ant-modal-body {
    padding-top: 3rem;
  }
}

.security-assessments-container {
  background: @white;
}
.iframe-image-style {
  width: 100%;
}

//custom-card-styles
.custom-card-body {
  margin-right: 1.5%;
  margin-bottom: 1.5%;
  height: 12rem;
  .ant-card-body {
    height: 12rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 0.1px solid @inputTextFieldBorderColor;
    border-radius: 8px;
  }
}

.custom-card-content {
  height: 12rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.custom-card-header-content {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1rem;
}
.custom-card-footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  height: 100%;
  .asset-authorisation-text-button {
    font-size: 1.2rem;
    text-align: left;
    color: @viewAllDarkBlue;
    font-weight: 400;
    &:hover {
      background: none;
    }
  }
  .kx-link {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 21px;
    text-decoration-line: underline;
    color: @cardButton;
  }
}
.custom-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px dashed @dividerColor2;
}

.custom-card-header-icon {
  padding-right: 1rem;
}

.custom-card-icon {
  height: 7vh;
}

.custom-card-key-asset {
  margin-top: 0.75rem;
}

.custom-card-footer {
  &-info {
    display: flex;
    padding-bottom: 1rem;
    &-text {
      margin-left: 0.5rem;
    }
    &-icon {
      padding-right: 0.3rem;
    }
  }
  &-popover-info {
    width: 20rem;
    padding: 0.5rem;
    text-align: justify;
  }
}

.custom-card-tags {
  display: flex;
  justify-content: space-between;
  max-width: 85%;
  span:first-child {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-content {
    background-color: @customizebgColor;
    border-radius: 1rem;
    padding: 0.25rem 0.75rem;
  }
}
//multiselect-consistant-scroll
.rc-virtual-list-scrollbar-show {
  display: block !important;
}

//spinner posiiton fro features portlets
.spin-loader-custom-class {
  width: 100%;
  margin: 11rem auto;
}

.cbo-drawer {
  .ant-drawer-header {
    background-color: @cboPrimaryColor;
    color: @white;
    border-radius: 0;
    .ant-drawer-extra {
      flex: none;
    }
  }
  .ant-drawer-body {
    background-color: @backgroundColor;
    padding: 1.5rem;
  }
  .ant-drawer-title {
    color: @white;
    font-size: 1.1rem;
  }
  &-close_btn {
    height: 1rem;
  }
}
.cbo-iframe {
  height: 100vh;
  width: 100%;
}

.ant-form-item-label {
  font-weight: 600;
  font-size: 1rem;
}

.ant-dropdown-trigger {
  cursor: pointer;
}

.keyasset-iframe {
  height: 100vh;
  width: 100%;
}

.multi-select-dropdown-option.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  min-height: 4rem;
  .ant-select-selection-item {
    color: @lightThemeBorderGray;
    background-color: @backgroundDetails;
    border: 0.03125rem solid @cboPrimaryColor;
    height: 1.8rem;
  }
  .ant-select-selection-item {
    height: 3rem;
    line-height: 3rem;
  }
}
.multi-select-dropdown-checkbox.ant-checkbox-wrapper {
  padding-right: 5px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @cboPrimaryColor;
    border-color: @cboPrimaryColor;
  }
}
.multi-select-dropdown-option {
  border-bottom: 0.5px dashed @cboCollapseBorderBottom;
  .anticon-check {
    margin: 1rem 1rem 0 0;
  }
  .ant-select-item-option-content {
    display: flex;
    justify-content: start;
    padding-top: 0.25rem;
  }
  .ant-select-item-option-state {
    display: none;
  }
  a {
    text-decoration: underline;
  }
}
