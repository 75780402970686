@import '../../theme/theme.less';

.tooltip-container {
  background: @white;
  border-radius: .3rem;
  width: 30rem;
  border: 1px solid @keyContactsGreen;
}

.tooltip-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: @keyContactsGreen;
  padding: 1rem;

  .tooltip-index {
    text-align: center;
    display: block;
    height: 1.78rem;
    width: 1.78em;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.78rem;
    color: @white;
  }

  .tooltip-title, .tooltip-close-button {
    font-weight: bold;
    font-size: 1rem;
    color: @white;
  }
}

.tooltip-content {
  padding: 1.5rem;
  font-size: 1rem;
  text-align: left;
}

.tooltip-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  
  .skip-tour-button {
    border: none;
    color: @gray1;
  }
  &-right-part {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tooltip-button {
      border: none;
      padding: .5rem;
  
      svg {
        height: 2rem;
        width: 2rem;
        fill: @keyContactsGreen;
      }
      &-disabled {
        padding: .6rem .5rem 0;
        svg {
          height: 2rem;
          width: 2rem;
          color: @lightGrey;
        }
      }
    }
  
    .end-tour-button {
      border-radius: .3rem;
      background-color: @lightThemeGreenButton;
    }

  }
}
