/*
Added this to define global classes here
*/

.mb-0 {
  margin-bottom: 0;
}

.dashed-bottom-border {
  border-bottom: 1px dashed;
}

.w-7 {
  width: 7rem;
}

.mb-0-dot-5 {
    margin-bottom: 0.5rem;
}
